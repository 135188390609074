
.JobCard {
    width: 80%;
    position: relative;
    background-color: white;
    filter:drop-shadow(.5em .5em .25em #00000022);
    padding: 1em;
    margin:1em;
    margin-left: auto;
    margin-right:auto;
}

.JobCard-title{
    font-weight: bolder;
    font-size: large;
}