
.CompanyCard {
    width: 80%;
    position: relative;
    background-color: white;
    filter:drop-shadow(.5em .5em .25em #00000022);
    padding: 1em;
    margin:1em;
    margin-left: auto;
    margin-right:auto;
}

.CompanyCard-image{
    position: absolute;
    top: 0em;
    right:0em;
    padding-right: 1em;


}

.CompanyCard img{
    width: 50px;
    height: 50px;
    object-fit:scale-down;
}

.CompanyCard-title{
    font-weight: bolder;
    font-size: large;
}